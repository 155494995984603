import { HttpClient } from '../../helpers/HttpClient';
import { MIRCryptogramRequest } from './MIRCryptogramRequest';
import { MIRCryptogramResponse } from './MIRCryptogramResponse';
import { MIRDeepLinkRequest } from './MIRDeepLinkRequest';
import { MIRDeepLinkResponse } from './MIRDeepLinkResponse';


export class MIRApi {
    constructor(private client: HttpClient) {
    }

    public async createDeepLink(request: MIRDeepLinkRequest): Promise<MIRDeepLinkResponse> {
        return this.client.post<MIRDeepLinkResponse>('/payments/mirpay/deeplinks', request);
    }

    public async getCryptogram(request: MIRCryptogramRequest): Promise<MIRCryptogramResponse> {
        return this.client.post<MIRCryptogramResponse>('/payments/mirpay/cryptograms/get', request);
    }
}

