import { HttpClient } from '../../helpers/HttpClient';
import { CreateAltPayRequest } from './CreateAltPayRequest';
import { CreateAltPayResponse } from './CreateAltPayResponse';
import { SentPaymentDetailsRequest } from "./SentPaymentDetailsRequest";
import { SentPaymentDetailsResponse } from "./SentPaymentDetailsResponse";

export class AltPayApi {
  constructor(private client: HttpClient) {}

  public async createAltPayTransaction(request: CreateAltPayRequest): Promise<CreateAltPayResponse> {
    return this.client.post<CreateAltPayResponse>('/payments/altpay/pay', request);
  }

  public async sentPaymentDetails(request: SentPaymentDetailsRequest): Promise<SentPaymentDetailsResponse> {
    return this.client.post<SentPaymentDetailsResponse>('/stp/spei/payment-details', request)
  }
}
