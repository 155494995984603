import { hex2b64 } from "../dependencies/jsbn/base64";
import { JSEncryptRSAKey } from "./JSEncryptRSAKey";

export interface IJSEncryptOptions {
    key: string;
}

/**
 *
 * @param {Object} [options = {}] - An object to customize JSEncrypt behaviour
 * parameters are:
 * - key        {string}  public key in string representation
 * @constructor
 */
 export class JSEncrypt {
    constructor(options:IJSEncryptOptions) {
        if (options.key == null || options.key.length == 0) {
            throw new Error("Public key required");
        }
        options = options;
        // The private and public key.
        this.key = new JSEncryptRSAKey(options.key);
    }

    private key:JSEncryptRSAKey;

    /**
     * Proxy method for RSAKey object's encrypt, encrypt the string using the public
     * components of the rsa key object. Note that if the object was not set will be created
     * on the fly (by the getKey method) using the parameters passed in the JSEncrypt constructor
     * @param {string} str the string to encrypt
     * @return {string} the encrypted string encoded in base64
     * @public
     */
    public encrypt(str:string) {
        // Return the encrypted string.
        try {
            return hex2b64(this.getKey().encrypt(str));
        } catch (ex) {
            console.log(ex);
            return false;
        }
    }

    /**
     * Getter for the current JSEncryptRSAKey object. If it doesn't exists a new object
     * will be created and returned
     * @param {callback} [cb] the callback to be called if we want the key to be generated
     * in an async fashion
     * @returns {JSEncryptRSAKey} the JSEncryptRSAKey object
     * @public
     */
    public getKey(cb?:() => void) {
        return this.key;
    }
}