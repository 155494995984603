export class KeyValuePair<TKey, TValue> implements IKeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;

    constructor(key: TKey, value: TValue) {
        this.key = key;
        this.value = value;
    }
}

export interface IKeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;
}