import { HttpClient } from '../../helpers/HttpClient';
import { TinkoffInstallmentTransactionRequest } from './TinkoffInstallmentTransactionRequest';
import { TinkoffInstallmentTransactionResponse } from './TinkoffInstallmentTransactionResponse';


export class TinkoffInstallmentApi {
    constructor(private client: HttpClient) {
    }

    public async createTransaction(request: TinkoffInstallmentTransactionRequest): Promise<TinkoffInstallmentTransactionResponse> {
        return this.client.post<TinkoffInstallmentTransactionResponse>('/payments/qr/sbp/link', request);
    }
}

