import { BaseResponse } from './BaseResponse';

export interface TerminalInfoResponse extends BaseResponse {
    Model: TerminalInfoResponseModel;
}

export interface TerminalInfoResponseModel {
    LogoUrl: string;
    TerminalUrl: string;
    TerminalFullUrl: string;
    TerminalName: string;
    IsCharity: boolean;
    IsTest: boolean;
    ExternalPaymentMethods: ExternalPaymentMethod[];
    AgreementPath: string;
    IsCvvRequired: boolean;
    SkipExpiryValidation: boolean;
    Features: Features;
    SupportedCards: CardType[];
    DisplayAdvertiseBannerOnWidget: boolean;
    BannerAdvertiseUrl?: string
}

export interface ExternalPaymentMethod {
    Type: ExternalPaymentMethods;
    Enabled: boolean;
    AppleMerchantId: string;
    AllowedPaymentMethods: string[];
    ShopId: string;
    ShowCaseId: string;
    OldFlow?: boolean;
    MinSum?: number;
    MaxSum?: number;
    TcsCreditPeriodSettings?: TcsCreditPeriodSettings;
    CashMethods?: CashMethods[];
    MinAmount?: number;
}

export interface TcsCreditPeriodSettings {
    IsEnabled?: boolean;
    Periods?: string[];
}

export interface Features {
    IsAllowedNotSanctionedCards: boolean;
    IsQiwi: boolean;
    IsSaveCard: SaveCard;
    IsPayOrderingEnabled?: boolean;
}

export enum ExternalPaymentMethods {
    ApplePay = 0,
    GooglePay = 1,
    MasterPass = 2,
    YandexPay = 3,
    TinkoffInstallmentPay = 4,
    SbpPay = 5,
    TinkoffPay = 6,
    MirPay = 7,
    Spei = 8,
    TcsBnplDolyame = 9,
    MtsPay = 10,
    Som = 11,
    SberPay = 12,
    Loan = 13,
    Cash = 14,
    CardInstallmentMX = 16,
}

export enum CashMethods {
    Oxxo = 0,
    CStores = 1,
}

export enum CardType {
    Visa = 0,
    MasterCard = 1,
    Maestro = 2,
    AmericanExpress = 3,
    DinersClub = 4,
    Discover = 5,
    CarteBlanche = 6,
    EnRoute = 7,
    JCB = 8,
    PRO100 = 9,
    MIR = 10,
    Belcard = 11,
    Troy = 12,
    UnionPay = 13,
    Elcard = 14,
    Humo = 15,
    Uzcard = 16,
    Carnet = 17,
    Unknown = 100,
}

export enum SaveCard {
    Classic = 0,
    New = 1,
    Optional = 2,
    Forced = 3,
}
