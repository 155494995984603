export class HttpClient {
    
    constructor(private baseUri: string, private parseJson = true) {
        if (baseUri == null) {
            throw new Error("'baseUri' required")
        }
    }

    /**
     * 
     * @param endpoint 
     * @param params query params
     * @param headers if not set - default headers: 'Content-Type', 'application/json'
     * @returns 
     */
    public async get<T>(endpoint: string, params?: Record<string, (string|undefined)>, headers?: Record<string, string>):Promise<T> {

        return new Promise<T>((resolve, reject) => {
            const url = new URL(endpoint, this.baseUri);
            
            if (params != null) {
                let filteredParams = Object.keys(params)
                .filter(k => params[k] != null)
                .reduce((a, k) => ({ ...a, [k]: params[k] }), {});
                url.search = new URLSearchParams(filteredParams).toString();
            }

            const xhr = new XMLHttpRequest();
            xhr.open('GET', url.toString());

            if (headers) {
                Object.entries(headers).forEach(([key, value]) => {
                    xhr.setRequestHeader(key, value);
                })
            } else {
                xhr.setRequestHeader('Content-Type', 'application/json');
            }

            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    if (this.parseJson) {
                        try {
                            const parsedJson = JSON.parse(xhr.response);
                            resolve(parsedJson);
                        } catch {
                            reject(new Error(`Failed to parse JSON from: ${xhr.response}`));
                        }
                    } else {
                        resolve(xhr.response);
                    }
                } else {
                    if (this.parseJson) {
                        try {
                            const parsedJson = JSON.parse(xhr.response);
                            reject(parsedJson);
                        } catch {
                            reject(new Error(`Failed to parse JSON from: ${xhr.response}`));
                        }
                    } else {
                        reject(xhr.response);
                    }
                }
            };

            xhr.onerror = () => {
                reject(new Error('HTTP xhr error'));
            };

            xhr.onabort = () => {
                reject(new Error('HTTP xhr abort'));
            };

            xhr.send();
        })
    }

    /**
     * 
     * @param endpoint 
     * @param body request body
     * @param headers if not set - default headers: 'Content-Type', 'application/json'
     * @returns 
     */
    public async post<T>(endpoint: string, body?: Record<string, unknown> | FormData | string, headers?: Record<string, string>):Promise<T> {

        return new Promise<T>((resolve, reject) => {
            const url = new URL(endpoint, this.baseUri);

            const xhr = new XMLHttpRequest();

            xhr.open('POST', url.toString());
            
            if (headers) {
                Object.entries(headers).forEach(([key, value]) => {
                    xhr.setRequestHeader(key, value);
                })
            } else {
                xhr.setRequestHeader('Content-Type', 'application/json');
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    if (this.parseJson) {
                        try {
                            const parsedJson = JSON.parse(xhr.response);
                            resolve(parsedJson);
                        } catch {
                            reject(new Error(`Failed to parse JSON from: ${xhr.response}`));
                        }
                    } else {
                        resolve(xhr.response);
                    }
                } else {
                    if (this.parseJson) {
                        try {
                            const parsedJson = JSON.parse(xhr.response);
                            reject(parsedJson);
                        } catch {
                            reject(new Error(`Failed to parse JSON from: ${xhr.response}`));
                        }
                    } else {
                        reject(xhr.response);
                    }
                }
            };

            xhr.onerror = (e) => {
                reject(new Error('HTTP xhr error: ' + e));
            };

            xhr.onabort = (e) => {
                reject(new Error('HTTP xhr abort: ' + e));
            };

            xhr.send(body != null ? typeof body == "string" ? body : body instanceof FormData ? body : JSON.stringify(body) : null);
        })
    }
}
