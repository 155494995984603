import { HttpClient } from '../../helpers/HttpClient';
import {QRRequest, QRSberPayRequest, QRStatusRequest, QRTinkoffRequest} from './QRRequest';
import { QRLinkResponse, QRImageResponse, QRResponse } from './QRModel';
import { QRStatusResponse } from './QRStatusResponse';


export class QRApi {
    constructor(private client: HttpClient) {
    }

    public async createSBPLink(request: QRRequest): Promise<QRResponse<QRLinkResponse>> {
        return this.client.post<QRResponse<QRLinkResponse>>('/payments/qr/sbp/link', request);
    }

    public async createSBPQRImage(request: QRRequest): Promise<QRResponse<QRImageResponse>> {
        return this.client.post<QRResponse<QRImageResponse>>('/payments/qr/sbp/image', request);
    }

    public async createTinkoffLink(request: QRTinkoffRequest): Promise<QRResponse<QRLinkResponse>> {
        return this.client.post<QRResponse<QRLinkResponse>>('/payments/qr/tinkoffpay/link', request);
    }

    public async createTinkoffQRImage(request: QRTinkoffRequest): Promise<QRResponse<QRImageResponse>> {
        return this.client.post<QRResponse<QRImageResponse>>('/payments/qr/tinkoffpay/image', request);
    }

    public async createSberPayLink(request: QRSberPayRequest): Promise<QRResponse<QRLinkResponse>> {
        return this.client.post<QRResponse<QRLinkResponse>>('/payments/qr/sberpay/link', request);
    }

    public async createSberPayQRImage(request: QRSberPayRequest): Promise<QRResponse<QRImageResponse>> {
        return this.client.post<QRResponse<QRImageResponse>>('/payments/qr/sberpay/image', request);
    }

    public async getStatus(transactionId: number): Promise<QRResponse<QRStatusResponse>> {
        return this.client.post<QRResponse<QRStatusResponse>>('/payments/qr/status/get', { TransactionId: transactionId });
    }

    public async waitStatus(request: QRStatusRequest): Promise<QRResponse<QRStatusResponse>> {
        return this.client.post<QRResponse<QRStatusResponse>>('/payments/qr/status/wait', request);
    }
}

