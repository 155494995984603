import { MIRApi } from './../contracts/MIR/MIRApi';
import { ApiClientOptions as ApiClientConfiguration } from '../contracts/ApiClientOptions';
import { MasterPassPayRequest } from '../contracts/request/MasterPassPayRequest';
import { MasterPassSaveCardRequest } from '../contracts/request/MasterPassSaveCardRequest';
import { MasterPassTokenRequest } from '../contracts/request/MasterPassTokenRequest';
import { PayRequest } from '../contracts/request/PayRequest';
import { StartApplePaySessionRequest } from '../contracts/request/StartApplePaySessionRequest';
import { BinInfoResponse } from '../contracts/response/BinInfoResponse';
import { MasterPassTokenResponse } from '../contracts/response/MasterPassTokenResponse';
import { PayResponse } from '../contracts/response/PayResponse';
import { StartApplePaySessionResponse } from '../contracts/response/StartApplePaySessionResponse';
import { TerminalInfoResponse } from '../contracts/response/TerminalInfoResponse';
import { HttpClient } from '../helpers/HttpClient';
import { QRApi } from '../contracts/QR/QRApi';
import { TinkoffInstallmentApi } from '../contracts/TinkoffInstallment/TinkoffInstallmentApi';
import { AltPayApi } from '../contracts/AltPay';
import jsSHA from "jssha";
import { StatusResponse } from '../contracts/response/StatusResponse';
import {TransactionInfoResponse} from '../contracts/response/TransactionInfoResponse';
import {CalculateInstallmentsMX} from "../contracts/response/CalculateInstallmentsMX";

export class ApiClient {
  private configuraion: ApiClientConfiguration;

  private readonly client: HttpClient;
  public readonly QRApi: QRApi;
  public readonly TinkoffInstallmentApi: TinkoffInstallmentApi;
  public readonly MIRApi: MIRApi;
  public readonly AltPayApi: AltPayApi;

  constructor(configuration: ApiClientConfiguration) {
    this.configuraion = configuration;
    this.client = new HttpClient(this.configuraion.host);
    this.QRApi = new QRApi(this.client);
    this.TinkoffInstallmentApi = new TinkoffInstallmentApi(this.client);
    this.MIRApi = new MIRApi(this.client);
    this.AltPayApi = new AltPayApi(this.client);
  }

  public async terminalInfo(publicId: string, paymentUrl: string, language: string): Promise<TerminalInfoResponse> {
    return await this.client.get<TerminalInfoResponse>('/merchant/configuration/', {
      terminalPublicId: publicId,
      paymentUrl: paymentUrl,
      language
    });
  }

  public async binInfo(
    bin: string,
    currency?: string,
    amount?: number,
    isAllowedNotSanctionedCards?: boolean,
    isQiwi?: boolean,
    IsCheckCard?: boolean,
    TerminalPublicId?: string
  ): Promise<BinInfoResponse> {
    const params: {[key: string]: string} = {
      Bin: bin.substring(0, 6),
      currency: currency,
      amount: amount?.toString(),
      isAllowedNotSanctionedCards: isAllowedNotSanctionedCards?.toString(),
      isQiwi: isQiwi?.toString(),
      IsCheckCard: IsCheckCard?.toString(),
      TerminalPublicId
    }

    if (bin.length > 6) {
      if (bin.length > 6 && bin.length <= 8) {
        params.SevenNumberHash =  new jsSHA('SHA-512', 'TEXT').update(bin.substring(0, 7)).getHash("HEX");
      }
      if (bin.length === 8) {
        params.EightNumberHash =  new jsSHA('SHA-512', 'TEXT').update(bin.substring(0, 8)).getHash("HEX");
      }
    }

    return await this.client.get<BinInfoResponse>(`/bins/info`, params);
  }

  public async auth(request: PayRequest): Promise<PayResponse> {
    return await this.client.post<PayResponse>('/payments/auth', request);
  }

  public async charge(request: PayRequest): Promise<PayResponse> {
    return await this.client.post<PayResponse>('/payments/charge', request);
  }

  public async status(transactionGuid: string): Promise<StatusResponse> {
    return await this.client.get<StatusResponse>(`/payments/status/${transactionGuid}`);
  }

  public async chargeByMasterPass(request: MasterPassPayRequest): Promise<PayResponse> {
    return await this.client.post<PayResponse>('/masterpass/charge', request);
  }

  public async authByMasterPass(request: MasterPassPayRequest): Promise<PayResponse> {
    return await this.client.post<PayResponse>('/masterpass/auth', request);
  }

  public async masterPassSaveCard(request: MasterPassSaveCardRequest): Promise<PayResponse> {
    return await this.client.post<PayResponse>('/masterpass/savecard', request);
  }

  public async masterPassRequestToken(request: MasterPassTokenRequest): Promise<MasterPassTokenResponse> {
    return await this.client.post<MasterPassTokenResponse>('/masterpass/requesttoken', request);
  }

  public async startApplePaySession(request: StartApplePaySessionRequest): Promise<StartApplePaySessionResponse> {
    return await this.client.post<StartApplePaySessionResponse>('/applepay/startsessioninternal', request);
  }

  public async transactionInfo(transactionId: string): Promise<TransactionInfoResponse> {
    return await this.client.get<TransactionInfoResponse>('/transactions/info/', {transactionId});
  }

  public async calculateInstallmentsMX(terminalPublicId: string, amount: number) {
    return await this.client.get<CalculateInstallmentsMX>('/installments/calculate/sum-by-period',
        {
          terminalPublicId,
          amount: amount?.toString()
        })
  }
}
