import { parseMonthYear, Utils } from './Utils';
import { CardType, ValidationError } from '../models';
import { BinInfoResponse, HttpClient } from '@cloudpayments/api-client';
import { configuration } from '../runtime-configuration/configuration';
import jsSHA from 'jssha';

export class ValidationHelper {
  private static nameRegex = /^[-–., A-Z0-9]+$/i;

  private static cvvRegex = /\d{3,4}/;

  private static cvvAmexRegex = /\d{4}/;

  private static isValidLuhn(luhn: string): boolean {
      let len = luhn.length,
          mul = 0,
          sum = 0;
      const prodArr = [
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
      ];

      if (len < 14) {
          return false;
      }

      while (len--) {
          sum += prodArr[mul][parseInt(luhn.charAt(len), 10)];
          mul ^= 1;
      }

      return sum % 10 === 0 && sum > 0;
  }

  public static async isHumoUzCard(cardNumber: string): Promise<boolean> {
      if (typeof cardNumber === 'undefined' || cardNumber === '') {
          return false;
      }

      const cardNumberWithoutSpace = Utils.removeSpaces(cardNumber);

      const httpClient = new HttpClient(configuration.api.host);

      const params: {[key: string]: string} = {
          Bin: cardNumberWithoutSpace.substring(0, 6),
          SevenNumberHash: new jsSHA('SHA-512', 'TEXT').update(cardNumberWithoutSpace.substring(0, 7)).getHash("HEX"),
          EightNumberHash: new jsSHA('SHA-512', 'TEXT').update(cardNumberWithoutSpace.substring(0, 8)).getHash("HEX"),
      };

      return (await httpClient.get<BinInfoResponse>('/bins/info', params))?.Model?.HideCvvInput ?? false;
  }

    public static validators = {
        name(value: string) {
            if (!value) {
                return ValidationError.Name_Empty;
            }

            if (!ValidationHelper.nameRegex.test(value)) {
                return ValidationError.Name_Invalid;
            }

            const nonSpacedText = Utils.removeSpaces(value);

            if (nonSpacedText.length > 26) {
                return ValidationError.Name_TooLong;
            }

            if (nonSpacedText.length < 2) {
                return ValidationError.Name_TooShort;
            }

            return null;
        },
        cardNumber(value: string): ValidationError | null {
            if (!value) {
                return ValidationError.CardNumber_Empty;
            }

            if (!ValidationHelper.isValidLuhn(Utils.removeSpaces(value))) {
                return ValidationError.CardNumber_Invalid;
            }

            return null;
        },
        expDateMonth(value: number | string): ValidationError | null {
            if (!value) {
                return ValidationError.ExpDateMonth_Empty;
            }

            const intValue = typeof value === 'number' ? value : parseInt(value, 10);
            if (isNaN(intValue) || intValue < 1 || intValue > 12) {
                return ValidationError.ExpDateMonth_Invalid;
            }

            return null;
        },
        expDateYear(value: number | string): ValidationError | null {
            if (value == null || value === '') {
                return ValidationError.ExpDateYear_Empty;
            }

            const intValue = typeof value === 'number' ? value : parseInt(value, 10);
            if (isNaN(intValue)) {
                return ValidationError.ExpDateYear_Invalid;
            }

            return null;
        },
        expDateMonthYear(value: number | string): ValidationError | null {
            if (!value) {
                return ValidationError.ExpDateMonthYear_Empty;
            }

            if (typeof value !== 'string') {
                throw new Error('expDateMonthYear can only validate string');
            }

            const monthYearObj = parseMonthYear(value);
            if (!monthYearObj) {
                return ValidationError.ExpDateMonthYear_Invalid;
            }

            return (
                ValidationHelper.validators.expDateMonth(monthYearObj.month) ||
        ValidationHelper.validators.expDateYear(monthYearObj.year)
            );
        },
        expDate(value: Date): ValidationError | null {
            if (!value) {
                return ValidationError.ExpDateMonthYear_Empty;
            }

            if (typeof value.getMonth !== 'function') {
                throw new Error('expDate can only validate date');
            }

            const now = new Date();
            const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

            if (value.getTime() < thisMonth.getTime()) {
                return ValidationError.ExpDateMonthYear_Invalid;
            }

            return null;
        },
        cvv(value: string, cardNumber?: string): ValidationError | null {
            if (!value) {
                return ValidationError.Cvv_Empty;
            }

            if (
                cardNumber != null &&
                Utils.getCardType(cardNumber) == CardType.Amex
            ) {
                if (!ValidationHelper.cvvAmexRegex.test(value)) {
                    return ValidationError.Cvv_Invalid;
                }
            } else {
                if (!ValidationHelper.cvvRegex.test(value)) {
                    return ValidationError.Cvv_Invalid;
                }
            }

            return null;
        },
    };
}
