export enum ValidationError {
    Name_Empty = 'Name_Empty',
    Name_Invalid = 'Name_Invalid',
    Name_TooLong = 'Name_TooLong',
    Name_TooShort = 'Name_TooShort',
    CardNumber_Empty = 'CardNumber_Empty',
    CardNumber_Invalid = 'CardNumber_Invalid',
    ExpDateMonth_Empty = 'ExpDateMonth_Empty',
    ExpDateMonth_Invalid = 'ExpDateMonth_Invalid',
    ExpDateYear_Empty = 'ExpDateYear_Empty',
    ExpDateYear_Invalid = 'ExpDateYear_Invalid',
    ExpDateMonthYear_Empty = 'ExpDateMonthYear_Empty',
    ExpDateMonthYear_Invalid = 'ExpDateMonthYear_Invalid',
    Cvv_Empty = 'Cvv_Empty',
    Cvv_Invalid = 'Cvv_Invalid'

}