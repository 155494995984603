import { JSEncrypt } from '@cloudpayments/jsencrypt';
import {PublicKey} from './../models/PublicKey';
import {PrivateTransactionData} from './../models/PrivateTransactionData';

export class HexPacketHelper {

    public static createCryptogram(publicId: string, cvvCode: string, publicKey: PublicKey): string;
    public static createCryptogram(publicId: string, privateData: PrivateTransactionData, publicKey: PublicKey): string;
    public static createCryptogram(publicId: string, privateData: PrivateTransactionData | string, publicKey: PublicKey): string {
        if (typeof privateData == 'string') {
            return this.createCvvHexPacket(privateData, publicKey.pem, parseInt(publicKey.version.toString(), 10));
        } else {
            return HexPacketHelper.createCardCryptogram(publicId, privateData, publicKey.pem);
        }
    }

    private static createHexPacketFromData(publicId: string, privateData: PrivateTransactionData, publicKey: string, keyVer: number): string {
        return HexPacketHelper.createHexPacket([
            1, // PKCS1 padding
            privateData.Number.substr(0, 6),
            privateData.Number.substr(privateData.Number.length - 4),
            privateData.ExpDateYear % 100,
            privateData.ExpDateMonth,
            keyVer,
            HexPacketHelper.createCardCryptogram(publicId, privateData, publicKey)
        ]);
    }

    private static createCvvHexPacket(data: string, publicKey: string, keyVer: number) {

        const jsencrypt = new JSEncrypt({key: publicKey});

        const cryptogram = jsencrypt.encrypt(data);
        if (cryptogram == null) {
            throw new Error('Invalid public key');
        }

        return HexPacketHelper.createHexPacket([
            3, // Number_ExpDate_Cvv_Terminal_Rsa_Pkcs1Padding
            keyVer,
            cryptogram
        ]);
    }

    public static createCardCryptogram(publicId: string, privateData: PrivateTransactionData, publicKey: string): string {

        const jsencrypt = new JSEncrypt({key: publicKey});

        const yearMonthString = HexPacketHelper.numberToEvenLengthString(privateData.ExpDateYear % 100)
            + HexPacketHelper.numberToEvenLengthString(privateData.ExpDateMonth);

        const data = [
            privateData.Number,
            yearMonthString,
            privateData.CVV,
            publicId
        ].join('@');

        const cryptogram = jsencrypt.encrypt(data);
        if (!cryptogram) {
            throw new Error('Invalid public key');
        }

        return cryptogram;
    }

    public static numberToEvenLengthString(val: number): string {
        const valStr = val.toString();
        return (valStr.length % 2 === 0 ? '' : '0') + valStr;
    }

    private static decimalToHexAndEvenLength(val: number) {
        const hexNumber = val.toString(16);
        return (hexNumber.length % 2 === 0 ? '' : '0') + hexNumber;
    }

    private static createHexPacket(items: any[]): string {
        const hexItems = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (typeof item === 'number') {
                hexItems.push(HexPacketHelper.decimalToHexAndEvenLength(item));
            }
            else {
                hexItems.push(item);
            }
        }
        return hexItems.join('');
    }

}
