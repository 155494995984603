import { DomainConfiguration } from './DomainConfiguration';

export const configuration: DomainConfiguration = {
    publicKey: {
        pem: process.env.KEY_DATA,
        version: process.env.KEY_VERSION,
    },
    api: {
        host: process.env.BILLING_API_URL,
    }
};

export const bayonetRequired = false;
