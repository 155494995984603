export type QRStatusResponse = {
    Success: boolean;
    TransactionId: number;
    ProviderQrId: string;
    StatusCode: QRStatusCode;
    Status: string;
}

export enum QRStatusCode {
    Created = 0,
    AwaitingAuthentication = 1,
    Authorized = 2,
    Completed = 3,
    Cancelled = 4,
    Declined = 5,
    Pending = 6
}
